import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MediaQuery from "react-responsive";
import Login from "../Login/Login";
import { useSelector } from "react-redux";
import "./Navbar.css";
import { toast, ToastContainer } from "react-toastify";

const Navbar = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const handleDropdownClick = () => {
    setOpen(!open);
  };

  const handleDropdownItemClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogoutDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    toast.info("Logged out successfully");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    const closeDropdown = (event) => {
      if (!event.target.closest(".dropdown")) {
        setOpen(false);
      }
      if (!event.target.closest(".loginDd")) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  return (
    <div className="navContainer">
      <div className="navHead">
        <span>10-15 Days Delivery</span>
        <img src={"https://kjar.in/images/kjar-logo.jpeg"} alt="KJAR-Logo" />
        <span className="mobileNum">
          Call US: +91-<a href="tel:9999479598"> 9999479598 </a>
        </span>
      </div>
      <div className="navBottom">
        <ToastContainer />
        <MediaQuery minWidth={768}>
          <div className="glassType">
            <Link
              to={`/`}
              className={`anchorTag ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <span className="">EYEGLASSES</span>
            </Link>
            <Link
              to={`/man-glasses`}
              className={`anchorTag ${
                location.pathname === "/man-glasses" ? "active" : ""
              }`}
            >
              <span className="">MEN</span>
            </Link>
            <Link
              to={`/women-glasses`}
              className={`anchorTag ${
                location.pathname === "/women-glasses" ? "active" : ""
              }`}
            >
              <span className="">WOMEN</span>
            </Link>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <div className="dropdown">
            <div className="dropdown-btn" onClick={handleDropdownClick}>
              Categories
              <div className="arrow-down"></div>
            </div>
            {open && (
              <div className="dropdown-content">
                <Link
                  to={`/`}
                  className="dropdown-link"
                  onClick={handleDropdownClick}
                >
                  EYEGLASSES
                </Link>
                <div className="hLine"></div>
                <Link
                  to={`/man-glasses`}
                  className="dropdown-link"
                  onClick={handleDropdownClick}
                >
                  MEN
                </Link>
                <div className="hLine"></div>
                <Link
                  to={`/women-glasses`}
                  className="dropdown-link"
                  onClick={handleDropdownClick}
                >
                  WOMEN
                </Link>
              </div>
            )}
          </div>
        </MediaQuery>
        <div className="personalBox">
          {user ? (
            <div className="nav-item" onClick={handleLogoutDropdown}>
              Hi, {user.name} <div className="arrow-down"></div>
            </div>
          ) : (
            <div className="nav-item" onClick={handleOpenLoginModal}>
              Login/Register
            </div>
          )}
          {isDropdownOpen && (
            <div className="loginDd">
              <Link
                to={`/order`}
                className="lItem"
                onClick={handleDropdownItemClick}
              >
                My Orders
              </Link>
              <div className="lLine"></div>
              <Link
                to={`/cart`}
                className="lItem"
                onClick={handleDropdownItemClick}
              >
                My Cart
              </Link>
              <div className="lLine"></div>
              <div className="lItem" onClick={handleLogout}>
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoginModalOpen && (
        <Login isOpen={isLoginModalOpen} onClose={handleCloseLoginModal} />
      )}
    </div>
  );
};

export default Navbar;
