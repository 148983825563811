import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Form, Input, Button } from "antd";
import "./OrderDetails.css";
import config from "../../config";

const { Item: FormItem } = Form;

const OrderDetails = ({ cartItems, onClose }) => {
  const [form] = Form.useForm();
  const modalRef = useRef(null);
  const navigate = useNavigate();

  const generateReceiptId = () => {
    return `${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  };

  const handleSubmit = async (values) => {
    await handlePayment(values);
  };

  const handlePayment = async (formData) => {
    const totalPrice = cartItems.reduce(
      (total, cartItem) =>
        total + cartItem.colorVariant.price * cartItem.quantity,
      40
    );
    const amount = totalPrice * 100;
    const currency = "INR";
    const receiptId = generateReceiptId();

    const token = localStorage.getItem("token");
    const response = await fetch(`${config.BASE_URL}/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
    });
    const order = await response.json();

    var options = {
      key: "",
      amount,
      currency,
      name: "Indus Eye Care",
      // description: "Test Transaction",
      image: "https://kjar.in/images/kjar-logo.jpeg",
      order_id: order.id,
      handler: async function (response) {
        const body = {
          ...response,
          cartItemIds: cartItems.map(({ id }) => id),
          cartItems: cartItems,
          deliveryInfo: {
            name: formData.name,
            email: formData.email,
            street: formData.address,
            city: formData.city,
            state: formData.state,
            zipCode: formData.pincode,
            phoneNumber: formData.mobileNumber,
            alternatePhone: formData.alternatePhone,
            landmark: formData.landmark,
          },
        };
        const token = localStorage.getItem("token");
        const validateResponse = await fetch(`${config.BASE_URL}/order/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        });
        const jsonResponse = await validateResponse.json();
        console.log(jsonResponse);
        navigate("/order");
      },
      prefill: {
        // name: formData.name,
        email: formData.email,
        contact: formData.mobileNumber,
      },
      // notes: {
      //   address: "Razorpay Office",
      // },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {});

    rzp1.open();
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <Modal
      open={true}
      title="Fill details and place order..."
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit} className="orderForm">
        <FormItem
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter your name" }]}
        >
          <Input placeholder="Enter your name" />
        </FormItem>
        <FormItem
          label="10-digit mobile number"
          name="mobileNumber"
          rules={[
            {
              required: true,
              message: "Please enter your valid mobile number",
              pattern: /^\d{10}$/,
            },
          ]}
        >
          <Input placeholder="Enter your mobile number" maxLength={10} />
        </FormItem>
        <FormItem
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email", type: "email" }
          ]}
        >
          <Input placeholder="Enter your email" />
        </FormItem>
        <FormItem
          label="Pincode"
          name="pincode"
          rules={[
            {
              required: true,
              message: "Please enter your pincode",
              pattern: /^\d{6}$/,
            },
          ]}
        >
          <Input placeholder="Enter Pincode" maxLength={6} />
        </FormItem>
        <FormItem
          label="City"
          name="city"
          rules={[{ required: true, message: "Please enter your city" }]}
        >
          <Input placeholder="Enter City" />
        </FormItem>
        <FormItem
          label="Address"
          name="address"
          rules={[{ required: true, message: "Please enter your address" }]}
        >
          <Input placeholder="Enter Address" />
        </FormItem>
        <FormItem
          label="State"
          name="state"
          rules={[{ required: true, message: "Please enter your state" }]}
        >
          <Input placeholder="Enter State" />
        </FormItem>
        <FormItem
          label="Alternate Number (Optional)"
          name="alternatePhone"
          rules={[
            {
              message: "Please enter a valid mobile number",
              pattern: /^\d{10}$/,
            },
          ]}
        >
          <Input
            placeholder="Enter your alternate mobile number"
            maxLength={10}
          />
        </FormItem>
        <FormItem label="Landmark (Optional)" name="landmark">
          <Input placeholder="Enter your landmark" />
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit">
            Pay Now
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default OrderDetails;
