import React, { useState } from "react";
import "./Login.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Form, Input, Button, Checkbox } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../config";

const Login = ({ isOpen, onClose }) => {
  const [action, setAction] = useState("Login");
  const [form] = Form.useForm();
  const [error, setError] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  if (!isOpen) return null;

  const handleFormSubmit = async (values) => {
    setError(null);
    const { name, email, password, otp } = values;

    try {
      let response;
      if (action === "Login") {
        response = await axios.post(`${config.BASE_URL}/login`, {
          email,
          password,
        });
        toast.success("Login successful");
        localStorage.setItem("token", response.data.token);
        setTimeout(() => {
          onClose();
          if (location.pathname === "/login") {
            navigate("/");
          }
          window.location.reload();
        }, 2000);
      } else if (otpSent) {
        response = await axios.post(`${config.BASE_URL}/verify-otp`, {
          email: userEmail,
          otp,
        });
        toast.success("OTP verified successfully. Logging in...");
        localStorage.setItem("token", response.data.token);
        setTimeout(() => {
          onClose();
          if (location.pathname === "/register") {
            navigate("/");
          }
          window.location.reload();
        }, 2000);
      } else {
        response = await axios.post(`${config.BASE_URL}/register`, {
          name,
          email,
          password,
        });
        toast.success(
          "Registration successful. Please check your email for the OTP to verify your account."
        );
        setOtpSent(true);
        setUserEmail(email);
        form.resetFields(["otp"]);
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        setError(error.response.data.error || "Server Error");
      } else if (error.request) {
        console.error("Network Error:", error.request);
        setError("Network Error: Please check your connection");
      } else {
        console.error("Error:", error.message);
        setError("Error: Something went wrong");
      }
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      title={action}
      className="login-modal"
      maskClosable={false} // Prevent modal from closing on outside click
    >
      <ToastContainer />

      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        className="login-form"
      >
        {action === "Sign Up" && !otpSent && (
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter your name" }]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>
        )}
        {(action === "Login" || !otpSent) && (
          <>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <Input.Password placeholder="Enter your password" />
            </Form.Item>
          </>
        )}
        {otpSent && (
          <Form.Item
            name="otp"
            label="OTP"
            rules={[
              {
                required: true,
                message: "Please enter the OTP sent to your email",
              },
            ]}
          >
            <Input placeholder="Enter OTP" />
          </Form.Item>
        )}
        {error && <div className="error">{error}</div>}
        {action === "Login" && (
          <Form.Item>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {otpSent ? "Verify OTP" : action}
          </Button>
        </Form.Item>
        <Form.Item>
          {action === "Login" ? (
            <p>
              Don't have an account?{" "}
              <Button type="link" onClick={() => setAction("Sign Up")}>
                Sign Up
              </Button>
            </p>
          ) : (
            <p>
              Already have an account?{" "}
              <Button type="link" onClick={() => setAction("Login")}>
                Login
              </Button>
            </p>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Login;
