import React, {useEffect} from "react";
import "./ManGlasses.css";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchProductsFailure, fetchProductsRequest, fetchProductsSuccess} from "../../redux/actions";
import config from "../../config";

const ManGlasses = () => {

    const dispatch = useDispatch();
    const products = useSelector(state => state.products.products);
    const loading = useSelector(state => state.products.loading);
    const error = useSelector(state => state.products.error);

    useEffect(() => {
        dispatch(fetchProductsRequest());

        fetch(`${config.BASE_URL}/products`)
            .then(response => response.json())
            .then(data => {
                // Assuming the API response has a gender field in the product
                const menProducts = data.filter(product => product.specifications.gender === "Male");
                dispatch(fetchProductsSuccess(menProducts));
            })
            .catch(error => dispatch(fetchProductsFailure(error)));
    }, [dispatch]);

    if (loading) return <div className="errorMsg">Loading...</div>;
    if (error) return <div className="errorMsg">Error: {error.message}</div>;

    return (
        <div className="glassContainer">
            <div className="manHeading">Best glasses that every Man loves</div>
            <div className="storeCon">
                {Array.isArray(products) && products.length > 0 ? (
                    products.map((product) => (
                        <Link className="linkPage" to={`/product/${product.productId}`} key={product.productId}>
                            <div className="product">
                                <div className="productImage">
                                    <img src={product?.colorVariants?.[0]?.images?.[0]} alt="Glass-Img"/>
                                </div>
                                <div className="productDetails">
                                    <div>{product.name}</div>
                                    <div className="productDes">
                                        <span>Price: ₹{product?.colorVariants?.[0]?.price}/-</span>
                                        <button className="buyNow">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))
                ) : (
                    <div className="errorMsg">No products available</div>
                )}
            </div>
        </div>
    );
};

export default ManGlasses;
