import  { Navigate } from 'react-router-dom'
const authGuard = Component => props => {
    const isLogin = localStorage.getItem('token')

    if (!isLogin) {
        return <Navigate to="/login"/>
    }

    return <Component {...props} />
}

export default authGuard