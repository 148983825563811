import React, { useEffect, useState } from "react";
import "./PurchaseDescription.css";
import SimilarProducts from "../SimilarProducts/SimilarProducts";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Login from "../../components/Login/Login";
import config from "../../config";

const PurchaseDescription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isLoggedIn = Boolean(localStorage.getItem("token"));

  const toggleFeaturesCollapsible = () => {
    setIsFeaturesOpen(!isFeaturesOpen);
  };

  const toggleDescriptionCollapsible = () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  const handleOpenLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`${config.BASE_URL}/products/${id}`);
        const jsonData = await response.json();
        setProduct(jsonData);
        setSelectedVariant(jsonData?.colorVariants?.[0]);
        setCurrentImageIndex(0); // Reset image index when product changes
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    if (!selectedVariant || !selectedVariant.images) return;

    const imageCount = selectedVariant.images.length;
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageCount);
    }, 2500);

    return () => clearInterval(interval); // Clear interval on component unmount or when selectedVariant changes
  }, [selectedVariant]);

  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleBuyNow = async () => {
    if (selectedVariant.stock === 0) return;

    try {
      if (!selectedVariant) {
        console.error("Selected variant is null or undefined");
        return;
      }

      const response = await axios.post(
        `${config.BASE_URL}/cart/add`,
        {
          productId: product.productId,
          quantity: 1,
          colorVariantId: selectedVariant.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data.message);
      navigate("/cart"); // Redirect to the cart after adding product
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  const handleBuyClick = () => {
    if (isLoggedIn) {
      handleBuyNow();
    } else {
      handleOpenLoginModal();
    }
  };

  const renderList = (content) => {
    return content
      .split("\n")
      .map((item, index) => <li key={index}>{item}</li>);
  };

  const renderSpecifications = (specifications) => {
    return Object.entries(specifications).map(([key, value], index) => (
      <li key={index}>
        <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
      </li>
    ));
  };

  if (!product) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="purchaseContainer">
      <div className="productDesCon">
        <div className="productContainer">
          {/* Image Slider */}
          <div className="imageSlider">
            <div className="images">
              {selectedVariant?.images?.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Product_Image_${index}`}
                  className={currentImageIndex === index ? "active" : ""}
                />
              ))}
            </div>
            <div className="radioButtons">
              {selectedVariant?.images?.map((_, index) => (
                <React.Fragment key={index}>
                  <input
                    type="radio"
                    name="image-slider"
                    id={`image-${index}`}
                    checked={currentImageIndex === index}
                    onChange={() => handleImageChange(index)}
                  />
                  <label htmlFor={`image-${index}`} />
                </React.Fragment>
              ))}
            </div>
          </div>

          {/* Features */}
          <div className={`collapsible ${isFeaturesOpen ? "active" : ""}`}>
            <button
              className="collapsible-button"
              onClick={toggleFeaturesCollapsible}
            >
              Features
            </button>
            {isFeaturesOpen && (
              <div className="content">
                <ul>{renderList(product.features)}</ul>
              </div>
            )}
          </div>

          {/* Specifications */}
          <div className={`collapsible ${isDescriptionOpen ? "active" : ""}`}>
            <button
              className="collapsible-button"
              onClick={toggleDescriptionCollapsible}
            >
              Specifications
            </button>
            {isDescriptionOpen && (
              <div className="content">
                <ul>{renderSpecifications(product.specifications)}</ul>
              </div>
            )}
          </div>
        </div>

        {/* Product Description */}
        <div className="product-Des">
          <div className="productDet">
            <span className="productName">{product.name}</span>
            <span className="shortDes">{product.description}</span>
            <span className="price">₹ {selectedVariant?.price}/-</span>
            {/* <span className="warn">(Include Non anti glare lenses)</span> */}
          </div>

          {/* Color Variants */}
          <div className="colorVariants">
            {product?.colorVariants?.map((colorVariant) => (
              <div
                key={colorVariant.id}
                className={`colorVariant ${
                  selectedVariant?.id === colorVariant.id ? "selected" : ""
                }`}
                onClick={() => setSelectedVariant(colorVariant)}
              >
                <span
                  className="dot"
                  style={{ background: colorVariant?.code }}
                />
                <span>{colorVariant?.name}</span>
              </div>
            ))}
          </div>
          {selectedVariant.stock === 0 ? (
            <div className="outStock">Out of Stock</div>
          ) : (
            <div className="stockValue">In Stock: {selectedVariant.stock}</div>
          )}

          {/* Action Button */}
          <div className="buyCart">
            <div
              className="buy-Now"
              onClick={handleBuyClick}
              style={{
                pointerEvents: selectedVariant.stock === 0 ? "none" : "auto",
                opacity: selectedVariant.stock === 0 ? 0.5 : 1,
                cursor: selectedVariant.stock === 0 ? "pointer" : "pointer",
              }}
            >
              {isLoggedIn ? "Buy Now" : "Login to Buy"}
            </div>
          </div>
        </div>
      </div>
      <SimilarProducts />
      {isLoginModalOpen && (
        <Login isOpen={isLoginModalOpen} onClose={handleCloseLoginModal} />
      )}
    </div>
  );
};

export default PurchaseDescription;
