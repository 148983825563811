import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchUserFailure,
  fetchUserRequest,
  fetchUserSuccess,
} from "./redux/actions";
import Navbar from "./components/Navbar/Navbar";
import DisplayGlass from "./container/DisplayGlass/DisplayGlass";
import PurchaseDescription from "./container/PurchaseDescription/PurchaseDescription";
import Error404 from "./components/ErrorPage/Error404";
import Footer from "./components/Footer/Footer";
import Cart from "./container/Cart/Cart";
import ManGlasses from "./container/ManGlasses/ManGlasses";
import WomenGlasses from "./container/WomenGlasses/WomenGlasses";
import Order from "./container/Order/Order";
import config from "./config";
import LoginNotFound from "./components/LoginNotFound/LoginNotFound";
import TermsandConditions from "./components/PrivacyData/TermsandConditions";
import Privacy from "./components/PrivacyData/Privacy";
import ReturnandRefund from "./components/PrivacyData/ReturnandRefund";
import ContactUS from "./components/PrivacyData/ContactUS";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchUserRequest());
      fetch(`${config.BASE_URL}/user/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          dispatch(fetchUserSuccess(data));
        })
        .catch((error) => {
          console.error("API call failed:", error);
          dispatch(fetchUserFailure(error));
        });
    }
  }, [dispatch]);

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<DisplayGlass />} />
          <Route path="/product/:id" element={<PurchaseDescription />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/order" element={<Order />} />
          <Route path="/product/:product_id/cart" element={<Cart />} />
          <Route path="/man-glasses" element={<ManGlasses />} />
          <Route path="/women-glasses" element={<WomenGlasses />} />
          <Route path="/login" element={< LoginNotFound/>} />
          <Route path="/privacypolicy" element={< Privacy/>} />
          <Route path="/termsandconditions" element={< TermsandConditions/>} />
          <Route path="/returnandrefund" element={< ReturnandRefund/>} />
          <Route path="/contact" element={< ContactUS/>} />
          <Route path="/*" element={<Error404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
