import React from "react";
import "./TermsandConditions.css";

export default function ContactUS() {
  return (
    <div className="termsCon">
      <div className="termsHead">Contact Us</div>
      <div className="conText">
        For more information or any questions regarding returns and refunds,
        please contact us at:
      </div>
      <div className="conText">Indus EyeCare Solutions LLP, Haryana, India</div>
      <div className="contactCon">
        <div>Email:</div>
        <span>
          <a href="mailto:info@induseye.in">info@induseye.in</a>
        </span>
      </div>
      <div className="contactCon">
        <div>Phone:</div>
        <span className="conTact">
          Call US: +91-<a href="tel:9999479598"> 9999479598 </a>
        </span>
      </div>
    </div>
  );
}
