import React from "react";
import "./Error404.css";

const Error404 = () => {
    return (
        <div className="errorPage">
            <h1>Oops!!! Page Not Found</h1>
        </div>
    );
};

export default Error404;
