import React, { useState } from 'react';
import Login from '../Login/Login'; 
import './LoginNotFound.css'; 

export default function LoginNotFound() {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    setLoginModalOpen(false);
  };

  return (
    <div className="login-not-found">
      <h2>Oops! You are not logged in.</h2>
      <button onClick={handleLoginClick}>Click here to login or sign up</button>
      <Login isOpen={isLoginModalOpen} onClose={handleModalClose} />
    </div>
  );
}
